const FirebaseConfig = {
  apiKey: 'AIzaSyACAEH-9qJ-iXBSkQ3UOBISOKeuHroLc5A',
  authDomain: 'mconnect-bdff8.firebaseapp.com',
  projectId: 'mconnect-bdff8',
  storageBucket: 'mconnect-bdff8.appspot.com',
  messagingSenderId: '112526388757',
  appId: '1:112526388757:web:754b56f5fe4bd8ea92a81c',
};

// const FirebaseConfig = {
//   apiKey: 'AIzaSyDh1IHZbhIDetZuN9JyuakxiAEvFxLN2BU',
//   authDomain: 'mconnect-57183.firebaseapp.com',
//   projectId: 'mconnect-57183',
//   storageBucket: 'mconnect-57183.appspot.com',
//   messagingSenderId: '769277021946',
//   appId: '1:769277021946:web:54502040d6e7f70c8f4343',
// };

export default FirebaseConfig;
