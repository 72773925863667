import React, { lazy, Suspense, useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { db } from "auth/FirebaseAuth";

export const AppViews = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    db.collection("auth")
      .get()
      .then((snap) => {
        let res = [];
        console.log(snap, "snap");
        snap.forEach((doc) => {
          res.push({ ...doc.data(), id: doc.id });
        });

        const authCheck = res.filter((data) => {
          return (
            data.adminUser == true && localStorage.getItem("auth_Id") == data.id
          );
        });
        if (authCheck.length == 0) {
          setOpen(true);
        }
        console.log(res, authCheck.length, "response");
      });
  }, []);
  // useEffect(() => {
  // Update the document title using the browser API
  // setTimeout(function () {
  //   localStorage.clear();

  //   window.location.reload(false);
  //   console.log("hello");
  // }, 2 * 60 * 60 * 1000);
  // }, []);
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/apps`}
          component={lazy(() => import(`./apps`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/components`}
          component={lazy(() => import(`./components`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/pages`}
          component={lazy(() => import(`./pages`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/maps`}
          component={lazy(() => import(`./maps`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/charts`}
          component={lazy(() => import(`./charts`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/employees`}
          component={lazy(() => import(`./employee`))}
        />
        {open != true && (
          <Route
            path={`${APP_PREFIX_PATH}/device`}
            component={lazy(() => import(`./device`))}
          />
        )}

        <Route
          path={`${APP_PREFIX_PATH}/docs`}
          component={lazy(() => import(`./docs`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/employees`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
